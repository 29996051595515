@import "asset";
@import "vars";
@import "~@/plugins/parallax/less/prx.less";

.plex() { font-family: 'IBM Plex Mono', monospace; }
.pretendard() { font-family:  'Pretendard' ,sans-serif; }
.fvh() { height: calc(var(--vh, 1vh) * 100); }

.appear-ts(@v) { transition: transform unit(@v, s) ease, opacity unit(@v, s) ease; }
.appear-ts(@v,@ease) { transition: transform unit(@v, s) @ease, opacity unit(@v, s) ease; }
.appear-delay(@v) {
  .tr-d(unit(@v * 0.4, s));
}

.space-m() { .ph(20); }
.space-d() { .ph(40);
  @media (min-width: @screen-dx-min) {
    & { .max-w(2680); .mh-c; .-box; }
  }
}

.wrap-m() { .ph(20); }
.wrap-tp() { .w(364); .ph(0); .mh-c; .-box; }
.wrap-tl() { .w(492); .ph(0); .mh-c; .-box; }
.wrap-d() { .w(800); .ph(0); .mh-c; .-box;
  @media (min-width: @screen-dx-min) {
    & { .max-w(2680); .mh-c; .-box; }
  }
}