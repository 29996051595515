.t {
  transform: none;
}
.prod h3 {
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
}
.prod p.astar {
  font-size: 0.625rem;
  line-height: 0.0625rem;
  line-height: 1;
  color: #9C9C9C;
}
.prod .holder {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}
.prod .hero {
  position: relative;
  height: 300vh;
}
.prod .hero .holder {
  height: 100vh;
  text-align: center;
  padding-top: 7.875rem;
  padding-right: 0rem;
  padding-bottom: 8rem;
  padding-left: 0rem;
}
.prod .hero .bg {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  background-color: #000;
  width: 100%;
  height: 100%;
}
.prod .hero h2 {
  font-size: 1rem;
  line-height: 0.0625rem;
  line-height: 1;
  color: #2e2e2e;
}
.prod .hero h2 + p {
  font-size: 2.375rem;
  line-height: 2.875rem;
  font-weight: 700;
  margin-top: 0.625rem;
  color: #1e1e1e;
}
.prod .hero img {
  display: block;
  margin-top: 5.625rem;
  margin-left: auto;
  margin-right: auto;
  width: 10.625rem;
  transition: opacity 0.5s 0s;
}
.prod .hero img + p {
  margin-top: 2rem;
}
.prod .hero .overview {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.prod .hero .overview p {
  color: #fff;
  width: 18rem;
  margin-left: auto;
  margin-right: auto;
}
.prod .hero .overview p + p {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  margin-top: 1.5rem;
}
.prod .hero[data-p='+'] img,
.prod .hero[data-p='11'] img,
.prod .hero[data-p='10'] img,
.prod .hero[data-p='9'] img,
.prod .hero[data-p='8'] img,
.prod .hero[data-p='7'] img,
.prod .hero[data-p='6'] img,
.prod .hero[data-p='5'] img,
.prod .hero[data-p='4'] img {
  opacity: 0.3;
}
.prod .feature {
  background-color: #000;
}
.prod .feature .holder {
  height: auto;
}
.prod .feature li {
  padding-top: 5rem;
  padding-right: 0rem;
  padding-bottom: 5rem;
  padding-left: 0rem;
  color: #fff;
}
.prod .feature li .ico {
  display: block;
  width: 3rem;
  height: 3rem;
}
.prod .feature li .ico ~ p {
  margin-top: 2.5rem;
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
}
.prod .feature li .ico + p + p {
  font-size: 1.25rem;
  line-height: 2rem;
  color: #C9C9C9;
}
.prod .feature li .video {
  margin-top: 3rem;
}
.prod .feature li .video video {
  display: block;
  width: 100%;
  object-fit: contain;
  border-radius: 0.25rem;
  overflow: hidden;
}
.prod .feature li .video video + video {
  margin-top: 1rem;
}
.prod .feature li .video p {
  margin-top: 1rem;
  text-align: center;
}
.prod .structure {
  padding-top: 5rem;
  padding-right: 0rem;
  padding-bottom: 5rem;
  padding-left: 0rem;
  background-color: #EFEFEF;
}
.prod .structure .holder {
  height: auto;
}
.prod .structure img {
  display: block;
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 19.625rem;
}
.prod .structure dl {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0rem;
  width: 16.375rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.75rem;
  line-height: 0.0625rem;
  line-height: 1;
  grid-row-gap: 10px;
}
.prod .structure dl dt {
  font-weight: 700;
  width: 7.75rem;
}
.prod .structure dl dd {
  font-weight: 500;
  color: #666;
  white-space: nowrap;
}
.prod .structure .holder > p {
  margin-top: 3rem;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  width: 18.9375rem;
  margin-left: auto;
  margin-right: auto;
}
.prod .structure .holder > p + p {
  margin-top: 3rem;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  width: 18.9375rem;
  margin-left: auto;
  margin-right: auto;
}
.prod .custom {
  padding-top: 5rem;
  padding-right: 0rem;
  padding-bottom: 5rem;
  padding-left: 0rem;
  overflow: hidden;
}
.prod .custom .viewer {
  margin-top: 4.5rem;
}
.prod .custom .viewer > p:first-child {
  font-size: 1.25rem;
  line-height: 0.0625rem;
  line-height: 1;
  font-weight: 700;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #DADADA;
}
.prod .custom .viewer .control {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: stretch;
}
.prod .custom .viewer .control .slider {
  position: relative;
  width: 9rem;
}
.prod .custom .viewer .control .slider .swiper-slide {
  height: 14.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.prod .custom .viewer .control .slider .swiper-pagination {
  display: none;
}
.prod .custom .viewer .control .slider .swiper-navigation {
  display: block;
}
.prod .custom .viewer .control .slider .swiper-navigation a {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -1.625rem;
  background-image: url('/img/cartridge/skin-custom-arrow.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.prod .custom .viewer .control .slider .swiper-navigation a:after {
  display: none;
}
.prod .custom .viewer .control .slider .swiper-navigation .swiper-button-prev {
  left: 0.5rem;
  transform: rotate(180deg);
}
.prod .custom .viewer .control .slider .swiper-navigation .swiper-button-next {
  right: 0.5rem;
}
.prod .custom .viewer .control .slider .swiper-navigation .swiper-button-disabled {
  opacity: 0.15;
}
.prod .custom .viewer .control .slider .img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.prod .custom .viewer .control .slider img {
  display: block;
  width: 3.375rem;
  height: 10rem;
}
.prod .custom .viewer .control .slider p {
  font-size: 1rem;
  line-height: 0.0625rem;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 0.75rem;
}
.prod .custom .viewer .control .slider + .slider {
  margin-left: 0.9375rem;
}
.prod .custom .viewer .control .slider + .slider img {
  width: 4rem;
  height: 4rem;
}
.prod .custom .viewer .result-txt {
  margin-top: 1.5rem;
  font-size: 0.5rem;
  line-height: 0.75rem;
  color: #666;
  text-align: center;
  height: 3.5rem;
}
.prod .custom .viewer .result {
  display: block;
  width: 19rem;
  height: 28rem;
  margin-left: auto;
  margin-right: auto;
}
.prod .spec {
  padding-top: 5rem;
  padding-right: 0rem;
  padding-bottom: 8rem;
  padding-left: 0rem;
}
.prod .spec li {
  margin-top: 5rem;
}
.prod .spec li img {
  display: block;
  width: 4.375rem;
  margin-left: auto;
  margin-right: auto;
}
.prod .spec li img + p {
  margin-top: 2rem;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  text-align: center;
}
.prod .spec li dl {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
}
.prod .spec li dl dt {
  font-weight: 700;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #DADADA;
}
.prod .spec li dl dd {
  text-align: center;
}
.prod .spec li dl dd b {
  display: block;
  font-weight: 600;
}
.prod .spec li dl + dl {
  margin-top: 3.5rem;
}
@media (min-width: 768px) {
  .prod .hero img {
    width: 11.625rem;
  }
  .prod .feature {
    height: 450vh;
  }
  .prod .feature ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0rem;
  }
  .prod .feature li {
    position: relative;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
  }
  .prod .feature li .ico ~ p {
    width: 19rem;
  }
  .prod .feature li .video {
    position: absolute;
    right: 0rem;
    top: 50%;
    left: auto;
    bottom: auto;
    transform: translateY(-50%);
    width: 19rem;
    height: auto;
    margin: 0;
  }
  .prod .feature li .video video {
    object-fit: cover;
    max-height: 86vh;
  }
  .prod .feature li .video.col-2 video {
    object-fit: cover;
    max-height: calc(43vh - 15px);
  }
  .prod .structure img {
    width: 39.25rem;
  }
  .prod .spec ul {
    grid-column-gap: 32px;
  }
  .prod .custom .viewer .result {
    width: 27.875rem;
    height: 41rem;
  }
}
@media (min-width: 1024px) {
  .prod h3 {
    font-size: 2.5rem;
    line-height: 0.0625rem;
    line-height: 1;
    text-align: center;
  }
  .prod p.astar {
    font-size: 0.875rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  .prod .hero h2 {
    font-size: 1.3125rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  .prod .hero h2 + p {
    font-size: 4rem;
    line-height: 0.0625rem;
    line-height: 1;
    margin-top: 2rem;
  }
  .prod .hero img {
    display: block;
    margin-top: 5.625rem;
    width: 16rem;
  }
  .prod .hero .overview {
    position: absolute;
    left: 0rem;
    top: 0rem;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
  .prod .hero .overview p {
    font-size: 1.5rem;
    line-height: 0.0625rem;
    line-height: 1;
    width: 29rem;
  }
  .prod .hero .overview p + p {
    font-size: 2rem;
    line-height: 3rem;
    margin-top: 2.5rem;
  }
  .prod .feature li .ico {
    width: 3.5rem;
    height: 3.5rem;
  }
  .prod .feature li .ico ~ p {
    margin-top: 3.5rem;
    font-size: 3.5rem;
    line-height: 4.5rem;
    width: 24.5rem;
  }
  .prod .feature li .ico ~ p + p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    
  }
  .prod .feature li .video {
    width: 26.3125rem;
    height: auto;
  }
  .prod .structure {
    height: 200vh;
    padding: 0;
  }
  .prod .structure .holder {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .prod .structure img {
    width: 60.125rem;
    max-width: 70vh;
  }
  .prod .structure dl {
    display: none;
  }
  .prod .structure dl + p {
    margin-top: 2.5rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
    width: 41.25rem;
  }
  .prod .structure .holder > p {
    width: 41.25rem;
  }
  .prod .custom .viewer {
    margin-top: 5rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
  }
  .prod .custom .viewer .control {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 27rem;
    padding-left: 2.5rem;
    box-sizing: border-box;
    justify-content: flex-start;
  }
  .prod .custom .viewer .control .slider {
    width: 100%;
    position: relative;
  }
  .prod .custom .viewer .control .slider > p {
    font-size: 1.5rem;
    line-height: 0.0625rem;
    line-height: 1;
    font-weight: 700;
    padding-bottom: 1rem;
    margin-top: 0rem;
    border-bottom: 1px solid #dadada;
  }
  .prod .custom .viewer .control .slider [slider] {
    position: relative;
    padding-top: 3rem;
    width: 11rem;
    margin-left: auto;
    margin-right: auto;
  }
  .prod .custom .viewer .control .slider .swiper-slide {
    height: auto;
  }
  .prod .custom .viewer .control .slider .swiper-pagination {
    display: block;
    position: relative;
    font-size: 1rem;
    line-height: 0.0625rem;
    line-height: 1;
    font-weight: 500;
    color: #969696;
    margin-top: 1.25rem;
  }
  .prod .custom .viewer .control .slider .swiper-navigation {
    display: block;
  }
  .prod .custom .viewer .control .slider .swiper-navigation a {
    width: 3rem;
    height: 3rem;
    margin-top: -1.5rem;
  }
  .prod .custom .viewer .control .slider .swiper-navigation .swiper-button-prev {
    left: -3rem;
  }
  .prod .custom .viewer .control .slider .swiper-navigation .swiper-button-next {
    right: -3rem;
  }
  .prod .custom .viewer .control .slider .img {
    flex: 0;
  }
  .prod .custom .viewer .control .slider img {
    display: block;
    width: 3.625rem;
    height: 10rem;
  }
  .prod .custom .viewer .control .slider .img + p {
    font-size: 1.25rem;
    line-height: 0.0625rem;
    line-height: 1;
    margin-top: 1.5rem;
  }
  .prod .custom .viewer .control .slider + .slider {
    margin-left: 0rem;
    margin-top: 1rem;
    flex-grow: 1;
  }
  .prod .custom .viewer .control .slider + .slider img {
    width: 5rem;
    height: 5rem;
  }
  .prod .custom .viewer .control .slider + .slider [slider] {
    padding-top: 5.5rem;
  }
  .prod .custom .viewer .control .slider + .slider [slider] + p {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 2.5rem;
    color: #666;
    text-align: center;
    position: absolute;
    left: 0rem;
    bottom: 0rem;
    right: auto;
    top: auto;
    width: 100%;
  }
  .prod .custom .viewer .control .slider + .slider .swiper-navigation a {
    margin-top: -1.5rem;
  }
  .prod .custom .viewer .result {
    margin-top: 0rem;
    width: 27rem;
    height: 43.5rem;
  }
  .prod .spec ul {
    grid-column-gap: 0;
    padding-top: 0rem;
    padding-right: 7rem;
    padding-bottom: 0rem;
    padding-left: 7rem;
  }
  .prod .spec li img {
    width: 7.5rem;
  }
  .prod .spec li img + p {
    font-size: 1.5rem;
    line-height: 2rem;
    
  }
  .prod .spec li dl {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0rem;
    grid-row-gap: 32px;
    margin-top: 4rem;
  }
  .prod .spec li dl dt {
    padding-bottom: 1rem;
    font-size: 1.25rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  .prod .spec li dl dd {
    text-align: center;
  }
  .prod .spec li dl dd b {
    display: block;
  }
  .prod .spec li dl + dl {
    margin-top: 4rem;
  }
  .prod .spec li:first-child dt:first-child {
    margin-left: -7rem;
  }
  .prod .spec li:last-child dt:first-child {
    margin-right: -7rem;
  }
  .prod .spec li + li dt {
    text-indent: -10000px;
  }
}
@media (min-width: 1280px) {
  .prod {
    position: relative;
  }
  .prod .hero {
    padding-top: 0rem;
    height: 500vh;
  }
  .prod .hero img {
    width: 17.375rem;
  }
  .prod .feature li .video {
    width: 39.5rem;
    height: auto;
  }
  .prod .feature li .video video + video {
    margin-top: 1.875rem;
  }
  .prod .structure img {
    width: 63.625rem;
  }
  .prod .custom .viewer .control {
    padding-left: 5.25rem;
  }
  .prod .custom .viewer .control .slider img {
    width: 5rem;
    height: 15rem;
  }
  .prod .custom .viewer .control .slider + .slider {
    margin-top: 2rem;
  }
  .prod .custom .viewer .control .slider + .slider .swiper-navigation a {
    margin-top: -1.25rem;
  }
  .prod .custom .viewer .result {
    width: 32.5625rem;
    height: 48rem;
  }
  .prod .spec ul {
    padding-top: 0rem;
    padding-right: 13rem;
    padding-bottom: 0rem;
    padding-left: 13rem;
  }
  .prod .spec li:first-child dt:first-child {
    margin-left: -13rem;
  }
  .prod .spec li:last-child dt:first-child {
    margin-right: -13rem;
  }
}
@media (min-width: 1600px) {
  .prod .hero img {
    width: 18.5rem;
  }
  .prod .feature li .video {
    width: 43.625rem;
    height: auto;
  }
  .prod .structure img {
    width: 70rem;
  }
  .prod .custom .viewer .control .slider [slider] {
    padding-top: 4rem;
  }
  .prod .custom .viewer .control .slider + .slider {
    margin-top: 2rem;
  }
  .prod .custom .viewer .control .slider + .slider [slider] {
    padding-top: 7rem;
  }
  .prod .custom .viewer .control .slider + .slider .swiper-navigation a {
    margin-top: -0.5rem;
  }
  .prod .custom .viewer .result {
    width: 35.9375rem;
    height: 53rem;
  }
  .prod .spec ul {
    padding-top: 0rem;
    padding-right: 18rem;
    padding-bottom: 0rem;
    padding-left: 18rem;
  }
  .prod .spec li:first-child dt:first-child {
    margin-left: -18rem;
  }
  .prod .spec li:last-child dt:first-child {
    margin-right: -18rem;
  }
}
[frame].prod .hero img {
  width: 9.6875rem;
}
[frame].prod .structure img {
  width: 16.1875rem;
}
@media (min-width: 768px) {
  [frame].prod .hero img {
    width: 10.5625rem;
  }
  [frame].prod .structure img {
    width: 20.9375rem;
  }
}
@media (min-width: 1024px) {
  [frame].prod .hero img {
    width: 14.5625rem;
  }
  [frame].prod .structure img {
    width: 26.75rem;
    max-width: 45vh;
  }
  [frame].prod .structure img + p {
    width: 41.25rem;
  }
}
@media (min-width: 1280px) {
  [frame].prod .hero {
    padding-top: 10rem;
  }
  [frame].prod .hero img {
    width: 15.9375rem;
  }
  [frame].prod .structure img {
    width: 28.3125rem;
  }
}
@media (min-width: 1600px) {
  [frame].prod .hero img {
    width: 17rem;
  }
  [frame].prod .structure img {
    width: 28.3125rem;
  }
}
