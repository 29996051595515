@use-vw: false;
@use-rem: true;
@vw: 1920px;
@rem: 16px;
@_sv: 0;
@_t1: 0;
@_t2: 0;

.clear() { zoom: 1;
  &:after { content: ''; display: block; clear: both; font-size: 0; height: 0; visibility: hidden; }
}
.cf() { .clear;
  & > * { .fl; }
}
.ir() { text-indent: -10000px; font-size: 0; line-height: 0; }

.-box() { box-sizing: border-box; }
.hide() { display: none; }
.block() { display: block; }
.ib() { display: inline-block; }
.inline() { display: inline; }
.table() { display: table; }
.cell() { display: table-cell; }
.flex() { display: flex; }
.inline-flex() { display: inline-flex; }
.flex-jc(@align: center) { justify-content: @align;}
.flex-ai(@align: center) { align-items: @align; }
.visible() { visibility: visible; }
.hidden() { visibility: hidden; }
.rel() { position: relative; }
.abs() { position: absolute; }
.fix() { position: fixed; }
.sticky() { position: sticky; }
.static() { position: static; }
.tc() { text-align: center; }
.tl() { text-align: left; }
.tr() { text-align: right; }
.fl() { float: left; }
.fr() { float: right; }
.vat() { vertical-align: top; }
.vam() { vertical-align: middle; }
.vab() { vertical-align: bottom; }
.extra-light() { font-weight: 200; }
.light() { font-weight: 300; }
.fw-light() { font-weight: 300; }
.regular() { font-weight: 400; }
.medium() { font-weight: 500; }
.semi-bold() { font-weight: 600; }
.bold() { font-weight: 700; }
.extra-bold() { font-weight: 800; }
.black() { font-weight: 900; }
.underline() { text-decoration: underline; }
.underline(@color) { .underline; text-decoration-color: @color; }
.cnt(@v) { content: @v; }
.cnt() { content: ''; .block; }

.sv(@v) {
  @_sv: if((isnumber(@v)),
  if((@use-vw), if((get-unit(@v) = ~""), unit(@v / @vw * 100, vw), @v),
  if((@use-rem), if((get-unit(@v) = ~""), unit(@v / @rem, rem), @v),
  if((get-unit(@v) = ~""), unit(@v, px), @v)  )), @v)
}

.gap-v(@v) { .sv(@v); margin-top: @_sv;
  &:first-child { margin-top: 0 }
}
.gap-h(@v) { .sv(@v); margin-left: @_sv;
  &:first-child { margin-left: 0 }
}
.gap-hr(@v) { .sv(@v); margin-right: @_sv;
  &:last-child { margin-right: 0 }
}

.-b(@color, @v:1px) { .sv(@v); border-bottom: @_sv solid @color; }
.-t(@color, @v:1px) { .sv(@v); border-top: @_sv solid @color; }
.-v(@color, @v:1px) { .sv(@v); .-b(@color, @_sv); .-t(@color, @_sv); }
.-l(@color, @v:1px) { .sv(@v); border-left: @_sv solid @color; }
.-r(@color, @v:1px) { .sv(@v); border-right: @_sv solid @color; }
.-h(@color, @v:1px) { .sv(@v); .-l(@color, @_sv); .-r(@color, @_sv); }
.-a(@color, @v:1px) {.sv(@v); border: @_sv solid @color; }
.-c(@color) { border-color: @color; color: @color; }

.-b() { border-bottom: 0; }
.-t() { border-top: 0; }
.-v() { .-b; .-t; }
.-l() { border-left: 0; }
.-r() { border-right: 0; }
.-h() { .-l; .-r; }
.-a() { border: 0; }
.dashed() { border-style: dashed; }

.t1(@v) { .sv(@v); @_t1: @_sv; }
.t2(@v) { .sv(@v); @_t2: @_sv; }

.w(@v) { .sv(@v); width: @_sv;}
.w() { width: auto; max-width: none; }
.min-w(@v) { .sv(@v); min-width: @_sv; }
.max-w(@v) { .sv(@v); max-width: @_sv; }
.h(@v) { .sv(@v); height: @_sv;}
.h() { height: auto; max-height: none; }
.min-h(@v) { .sv(@v); min-height: @_sv; }
.max-h(@v) { .sv(@v); max-height: @_sv; }
.wh(@v) { .w(@v); .h(@v); }
.wh(@w, @h) { .w(@w); .h(@h); }
.wh() {.w; .h;}
.calc-w(@w, @v){ .sv(@v); width: calc(@w - @_sv);}

.t(@v) { .sv(@v); top: @_sv; }
.r(@v) { .sv(@v); right: @_sv; }
.b(@v) { .sv(@v); bottom: @_sv; }
.l(@v) { .sv(@v); left: @_sv; }
.lt(@l:0, @t:0) { .l(@l); .t(@t); right: auto; bottom: auto; }
.lb(@l:0, @b:0) { .l(@l); .b(@b); right: auto; top: auto; }
.rt(@r:0, @t:0) { .r(@r); .t(@t); left: auto; bottom: auto; }
.rb(@r:0, @b:0) { .r(@r); .b(@b); left: auto; top: auto; }
.lt-c() { .lt(50%,50%); }

.pt(@v) { .sv(@v); padding-top: @_sv; }
.pr(@v) { .sv(@v); padding-right: @_sv; }
.pb(@v) { .sv(@v); padding-bottom: @_sv; }
.pl(@v) { .sv(@v); padding-left: @_sv; }
.p(@v) { .sv(@v); padding: @_sv; }
.p(@v, @h) { .pt(@v); .pr(@h); .pb(@v); .pl(@h); }
.p(@t, @h, @b) { .pt(@t); .pr(@h); .pb(@b); .pl(@h); }
.p(@t, @r, @b, @l) { .pt(@t); .pr(@r); .pb(@b); .pl(@l); }
.ph(@v) { .pr(@v); .pl(@v); }
.pv(@v) { .pt(@v); .pb(@v); }
.p() { padding: 0; }

.mt(@v) { .sv(@v); margin-top: @_sv; }
.mr(@v) { .sv(@v); margin-right: @_sv; }
.mb(@v) { .sv(@v); margin-bottom: @_sv; }
.ml(@v) { .sv(@v); margin-left: @_sv; }
.m(@v) { .sv(@v); margin: @_sv; }
.m(@v, @h) { .mt(@v); .mr(@h); .mb(@v); .ml(@h); }
.m(@t, @h, @b) { .mt(@t); .mr(@h); .mb(@b); .ml(@h); }
.m(@t, @r, @b, @l) { .mt(@t); .mr(@r); .mb(@b); .ml(@l); }
.mh(@v) { .mr(@v); .ml(@v); }
.mv(@v) { .mt(@v); .mb(@v); }
.m() { margin: 0; }

.mh-c() { margin-left: auto; margin-right: auto; }

.t { transform: none; }
.t-x(@v) { .sv(@v); transform: translateX(@_sv); }
.t-y(@v) { .sv(@v); transform: translateY(@_sv); }
.t-xy(@x, @y) { .t1(@x); .t2(@y); transform: translateX(@_t1) translateY(@_t2); }
.t-xyc() { transform: translate(-50%,-50%); }
.t-xc() { transform: translateX(-50%); }
.t-yc() { transform: translateY(-50%); }
.t-r(@r) { transform: rotate(@r); }
.t-rxy(@r,@x,@y) { transform: rotate(@r) translateX(@x) translateY(@y); }
.t-s(@v) { transform: scale(@v); }
.t-sx(@v) { transform: scaleX(@v); }
.t-sy(@v) { transform: scaleY(@v); }

.fs(@v) { .sv(@v); font-size: @_sv; }
.fs(@v, @lh) { .fs(@v); .lh(@lh);
  if((@lh < 6), ~'line-height: @{lh};');
}
.fs(@v, @lh, @ls) { .fs(@v); .lh(@lh); .ls(@ls);
  if((@lh < 6), ~'line-height: @{lh};');
}
.lh(@v) { .sv(@v); line-height: @_sv; }
.hlh(@v) { .h(@v); .lh(@v); }
.br(@v) { .sv(@v); border-radius: @_sv; }
.br-t(@v) { .sv(@v); border-radius: @_sv @_sv 0 0; }
.br-b(@v) { .sv(@v); border-radius: 0 0 @_sv @_sv; }
.br-l(@v) { .sv(@v); border-radius: @_sv 0 0 @_sv; }
.br-r(@v) { .sv(@v); border-radius: 0 @_sv @_sv 0; }
.ls(@v) { .sv(@v); letter-spacing: @_sv; }
.va(@v) { .sv(@v); vertical-align: @_sv; }
.ti(@v) { .sv(@v); text-indent: @_sv; }

.fill(@v) { fill:@v; }
.stroke(@v) { stroke:@v; }
.c() { color: inherit; }
.c(@v) { color: @v; }
.bg(@url) { background-image: url(@url);}
.bg(@url, @x, @y) { .bg(@url); .bg-xy(@x, @y); }
.bgc(@v:transparent) { background-color: @v;}
.bg-s(@w, @h) { .t1(@w); .t2(@h); background-size: @_t1 @_t2; }
.bg-w(@v) { .sv(@v); background-size: @_sv auto; }
.bg-h(@h) { .sv(@h); background-size: auto @_sv; }
.bg-x(@v) { .sv(@v); background-position-x: @_sv; }
.bg-y(@v) { .sv(@v); background-position-y: @_sv; }
.bg-xy(@x, @y) { .bg-x(@x); .bg-y(@y); }

.bg-c() { background-position: center; }
.bg-xc() { background-position-x: center; }
.bg-yc() { background-position-y: center; }
.no-repeat() { background-repeat: no-repeat;}
.no-repeat(@url) { .bg(@url); .no-repeat; }
.contain() { background-size: contain; }
.contain(@url) { .no-repeat(@url); .contain; }
.cover() { background-size: cover; }
.cover(@url) { .no-repeat(@url); .cover; }
.wf() { width: 100%; }
.hf() { height: 100%; }
.f() { .wh(100%); }
.pointer() { cursor: pointer; }

.keep-all() { word-break: keep-all; }
.break-all() { word-break: break-all; }
.nowrap() { white-space: nowrap; }
.pre-line() { white-space: pre-line; }
.darken() { mix-blend-mode: darken; }
.space-between() { justify-content: space-between; }
.space-around() { justify-content: space-around; }
.justify-center() { justify-content: center; }
.justify-end() { justify-content: flex-end; }
.justify-start() { justify-content: flex-start; }
.align-center() { align-items: center; }
.align-start() { align-items: flex-start; }
.align-end() { align-items: flex-end; }
.z(@v) { z-index: @v; }

.ib-list() { font-size: 0;
  & > * { .ib; .vat; }
}
.ib-list(@width) { .ib-list;
  & > * { width: @width; }
}

.split-line-list(@fs,@width,@height,@margin,@va,@color) { font-size: 0;
  > * { .ib; .fs(@fs); .vat;
    &:before { .cnt; .ib; .wh(@width, @height); background: @color; .mh(@margin); .va(@va); }
    &:first-child:before { .hide; }
  }
}

.col-list(@margin,@col) { .ib-list; margin-left: -@margin;
  & > * { padding-left: @margin; width: 100%/@col; .-box; }
}

.ellipsis() { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
.ellipsis(@line) { overflow: hidden; text-after-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: @line; }

.flex-wrap(@display: flex) { display: @display; flex-wrap: wrap; }
.flex-center(@display: flex) { display: @display; justify-content: center; align-items: center; }
.flex-middle(@display: flex) { display: @display; flex-flow: column; justify-content: center; }
.flex-column(@display: flex) { display: @display; flex-flow: column; align-items: center; }
.flex-row(@display: flex) { display: @display; flex-flow: row; }
.flex-list(@display: flex) { display: @display; >* { flex:1 } }

.grid(@col:1,@gap:0) { .sv(@gap); display: grid; grid-template-columns: repeat(@col, 1fr); grid-gap: @_sv; }

.arrow-down(@v) { .sv(@v);
  border-top: @_sv solid;
  border-right: @_sv solid transparent;
  border-bottom: 0;
  border-left: @_sv solid transparent;
}

.ease-out() { transition-timing-function: ease-out; }
.ease-in() { transition-timing-function: ease-in; }
.ease-in-out() { transition-timing-function: ease-in-out; }
.ease-linear() { transition-timing-function: linear; }

.tr-a(@t, @d:0s) { transition: all @t @d; }
.tr-t(@t, @d:0s) { transition: transform @t @d; }
.tr-o(@t, @d:0s) { transition: opacity @t @d; }
.tr-c(@t, @d:0s) { transition: color @t @d; }
.tr-to(@tt, @to:@tt, @d:0s) { transition: transform @tt @d, opacity @to @d; }
.tr-d(@d) { transition-delay: @d; }

.o(@v) { opacity: @v; }
.o(@t, @v) { transition: opacity @t; opacity: @v; }
.o(@t, @d, @v) { transition: opacity @t @d; opacity: @v; }

.crop() { overflow: hidden; }
.scroll() { overflow-y: scroll; }

@hover: ~'.no-touch &:hover:not(.hover-disable)';
@hover-press: ~'.no-touch &:hover:not(.hover-disable), &:active';
@router-active: ~'&:not(.exact).router-link-active, &.exact.router-link-exact-active';
