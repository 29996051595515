@import "proj";

.prod {
  h3 { .fs(32,40); .tc; }
  p.astar { .fs(10,1); .c(#9C9C9C); }
  .holder { .rel; .h(100vh); .-box; .crop; }
  .hero { .rel; .h(300vh);
    .holder { .h(100vh); .tc; .p(126,0,128); }
    .bg { .abs; .lt; .bgc(#000); .f; }
    h2 { .fs(16,1); color: #2e2e2e; }
    h2 + p { .fs(38,46); .bold; .mt(10); color: #1e1e1e; }
    img { .block; .mt(90); .mh-c; .w(170); .tr-o(.5s); }
    img + p { .mt(32); }
    .overview { .abs; .lt; .f; .flex; flex-direction: column; .justify-center; .tl;
      p { color: #fff; .w(288); .mh-c; }
      p + p { .fs(20,32); .bold; .mt(24); }
    }
    .progress-up(4, {
      img { .o(.3); }
    });
  }
  .feature { .bgc(#000);
    .holder { .h(auto); }
    li { .p(80,0); color: #fff;
      .ico { .block; .wh(48); }
      .ico ~ p { .mt(40); .fs(40,48); .bold; }
      .ico + p + p { .fs(20,32); color: #C9C9C9; }
      .video { .mt(48);
        video { .block; .wf; object-fit: contain; .br(4); .crop; }
        video + video { .mt(16); }
        p { .mt(16); .tc; }
      }
    }
  }
  .structure { .p(80,0); .bgc(#EFEFEF);
    .holder { .h(auto); }
    img { .block; .mt(40); .mh-c; .w(314); }
    dl { .mt(40); .grid(2); .w(262); .mh-c; .fs(12,1); grid-row-gap: 10px;
      dt { .bold; .w(124); }
      dd { .medium; color: #666; .nowrap; }
    }
    .holder > p { .mt(48); .fs(20,32); .bold; .w(303); .mh-c; }
    .holder > p + p { .mt(48); .fs(20,32); .bold; .w(303); .mh-c; }
  }
  .custom { .p(80,0); .crop;
    .viewer { .mt(72);
      > p:first-child { .fs(20,1); .bold; .pb(12); .-b(#DADADA); }
      .control { .rel; .flex-center; align-items: stretch;
        .slider { .rel; .w(144);
          .swiper-slide { .h(238); .flex; flex-direction: column; align-items: center; }
          .swiper-pagination { .hide; }
          .swiper-navigation { .block;
            a { .wh(24); .mt(-26); .contain('/img/cartridge/skin-custom-arrow.svg');
              &:after { .hide; }
            }
            .swiper-button-prev { .l(8); transform: rotate(180deg); }
            .swiper-button-next { .r(8); }
            .swiper-button-disabled { .o(.15); }
          }
          .img { flex: 1; .flex-center; }
          img { .block; .wh(54,160); }
          p { .fs(16,1); .medium; text-transform: uppercase; .mt(12); }
        }
        .slider + .slider { .ml(15);
          img { .wh(64); }
        }
      }
      .result-txt { .mt(24); .fs(8,12); color: #666; .tc; .h(56); }
      .result { .block; .wh(304,448); .mh-c; }
    }
  }
  .spec { .p(80,0,128);
    li { .mt(80);
      img { .block; .w(70); .mh-c; }
      img + p { .mt(32); .fs(20,32); .bold; .tc; }
      dl { .mt(48); .grid(1, 24px);
        dt { .bold; .pb(12); .-b(#DADADA); }
        dd { .tc;
          b { .block; .semi-bold; }
        }
      }
      dl + dl { .mt(56); }
    }
  }
}

@media (min-width: @screen-tp-min) {
  .prod {
    .hero {
      img { .w(186); }
    }
    .feature { .h(450vh);
      ul { .grid(3); }
      li { .rel; .w(100vw); .h(100vh); .-box; .inline-flex; flex-direction: column; .justify-center;
        .ico ~ p { .w(304); }
        .video { .abs; .rt(0, 50%); .t-yc; .wh(304,auto); .m;
          video { object-fit: cover; .max-h(86vh); }
          &.col-2 {
            video { object-fit: cover; max-height: calc(43vh - 15px); }
          }
        }
      }
    }
    .structure {
      img { .w(628); }
    }
    .spec {
      ul { grid-column-gap: 32px; }
    }
    .custom {
      .viewer {
        .result { .wh(446,656); }
      }
    }
  }
}
@media (min-width: @screen-tl-min) {
  .prod {
    h3 { .fs(40,1); .tc; }
    p.astar { .fs(14,1); }
    .hero {
      h2 { .fs(21,1); }
      h2 + p { .fs(64,1); .mt(32); }
      img { .block; .mt(90); .w(256); }
      .overview { .abs; .lt; .f; .flex; flex-direction: column; .justify-center; .tl;
        p { .fs(24,1); .w(464); }
        p + p { .fs(32,48); .mt(40); }
      }
    }
    .feature {
      li {
        .ico { .wh(56); }
        .ico ~ p { .mt(56); .fs(56,72); .w(392); }
        .ico ~ p + p { .fs(24,40); }
        .video { .wh(421,auto); }
      }
    }
    .structure { .h(200vh); .p;
      .holder { .h(100vh); .flex-center; flex-direction: column; }
      img { .w(962); .max-w(70vh); }
      dl { .hide; }
      dl + p { .mt(40); .fs(24,40); .w(660); }
      .holder > p { .w(660); }
    }
    .custom {
      .viewer { .mt(80); .flex; flex-direction: row-reverse; align-items: stretch;
        .control { flex:1; .flex; flex-direction: column; .w(432); .pl(40); .-box; justify-content: flex-start;
          .slider { .wf; .rel;
            > p { .fs(24,1); .bold; .pb(16); .mt(0); .-b(#dadada); }
            [slider] { .rel; .pt(48); .w(176); .mh-c; }
            .swiper-slide { .h(auto); }
            .swiper-pagination { .block; .rel; .fs(16,1); .medium; color: #969696; .mt(20); }
            .swiper-navigation { .block;
              a { .wh(48); .mt(-24); }
              .swiper-button-prev { .l(-48); }
              .swiper-button-next { .r(-48); }
            }
            .img { flex: 0; }
            img { .block; .wh(58,160); }
            .img + p { .fs(20,1); .mt(24); }
          }
          .slider + .slider { .ml(0); .mt(16); flex-grow: 1;
            img { .wh(80); }
            [slider] { .pt(88); }
            [slider] + p { .fs(12,16); .mt(40); color: #666; .tc; .abs; .lb; .wf; }
            .swiper-navigation a { .mt(-24); }
          }
        }
        .result { .mt(0); .wh(432,696); }
      }
    }
    .spec {
      ul { grid-column-gap: 0; .p(0,112); }
      li {
        img { .w(120); }
        img + p { .fs(24,32); }
        dl { .grid(1); grid-row-gap: 32px; .mt(64);
          dt { .pb(16); .fs(20,1); }
          dd { .tc;
            b { .block; }
          }
        }
        dl + dl { .mt(64); }
        &:first-child dt:first-child { .ml(-112); }
        &:last-child dt:first-child { .mr(-112); }
      }
      li + li {
        dt { text-indent: -10000px; }
      }
    }
  }
}
@media (min-width: @screen-ds-min) {
  .prod { .rel;
    .hero { .pt(0); .h(500vh);
      img { .w(278); }
    }
    .feature {
      li {
        .video { .wh(632,auto);
          video + video { .mt(30); }
        }
      }
    }
    .structure {
      img { .w(1018); }
    }
    .custom {
      .viewer {
        .control { .pl(84);
          .slider {
            img { .wh(80,240); }
          }
          .slider + .slider { .mt(32);
            .swiper-navigation a { .mt(-20); }
          }
        }
        .result { .wh(521,768); }
      }
    }
    .spec {
      ul { .p(0,208); }
      li {
        &:first-child dt:first-child { .ml(-208); }
        &:last-child dt:first-child { .mr(-208); }
      }
    }
  }
}
@media (min-width: @screen-dl-min) {
  .prod {
    .hero {
      img { .w(296); }
    }
    .feature {
      li {
        .video { .wh(698,auto); }
      }
    }
    .structure {
      img { .w(1120); }
    }
    .custom {
      .viewer {
        .control {
          .slider {
            [slider] { .pt(64); }
          }
          .slider + .slider { .mt(32);
            [slider] { .pt(112); }
            .swiper-navigation a { .mt(-8); }
          }
        }
        .result { .wh(575,848); }
      }
    }
    .spec {
      ul { .p(0,288); }
      li {
        &:first-child dt:first-child { .ml(-288); }
        &:last-child dt:first-child { .mr(-288); }
      }
    }
  }
}
